<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
        <h4 class="">Blogs/View</h4>
        <div>
          <router-link :to="{...previousRoute}">
            <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
          </router-link>
        </div>
      </div>

      <div ref="singleBlogViewRef">
        <div class="content-body">
          <section class="page-user-profile">
            <div class="card">
              <div class="card-body">
                <div class="row d-flex justify-content-center">
                  <div class="col-6 ">
                    <div class="">
                      <div class="d-flex justify-content-center pb-2">
                        <div class="position-relative">
                          <img :src="blog.image" class="img-fluid " alt="blog image">
                          <div
                              class="position-absolute update-blog-image-avatar d-flex align-items-center justify-content-center"
                              @click="openImageUpdateModal">
                            <i class='bx bxs-camera'></i>
                          </div>
                        </div>
                      </div>
                      <h2 class="media-heading text-center"> {{ blog.title }}</h2>
                      <h4 class="media-heading text-center">{{ blog.blogCategory && blog.blogCategory.name
                                             ? blog.blogCategory.name
                                                 : 'No Blog Category Assigned' }}</h4>
                      <h6 v-if="blog?.blogTags?.length > 0" class="text-center">
                        <span class="badge badge-circle-light mr-1 " v-for="(tag,index) in blog.blogTags" :key="index">
                          {{tag.name}}
                        </span>
                      </h6>
                      <h6 class="text-center" v-else>
                        No Blog Tags Assigned
                      </h6>
                        <p class="text-center"><span class="text-muted">Read Time: {{ blog.read_time }}, Read Count: {{ blog.read_count }}</span></p>
                        <p class="text-center"><span class="text-muted" >Status: <span :class="blog.status === 'Active' ? 'badge badge-success' : 'badge badge-danger' ">{{blog.status}}</span>
                          Is Featured: <span :class="blog.is_featured === 'Yes' ? 'badge badge-success' : 'badge badge-danger' ">{{ blog.is_featured }}</span></span>
                        </p>

                    </div>

                  </div>
                </div>
                <div class="row">
                  <div class="col-12" v-html="blog.description">

                  </div>

                </div>
              </div>
            </div>

          </section>
        </div>



        <!-- for modal for image update -->
        <div class="" data-toggle="modal" data-target="#updateAvatarModal"></div>
        <UpdateImageModal v-on:getSingleView="uploadImage($event)"
                          :previousImage="blog?.image ?? defaultImage"/>


      </div>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout";

import UpdateImageModal from "@/views/backEnd/blogs/includes/BlogImageUpdateModal.vue";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core packages
import {mapActions, mapGetters} from 'vuex'

export default {
  components: {
    AppLayout,
    UpdateImageModal,

  },
  mixins: [ShowToastMessage, Loader, Authorization],
  name: "BlogView.vue",

  data() {
    return {
      defaultImage: `${process.env.VUE_APP_API_URL}default/images/blogs/no-image.png`,

      getBlogParams: {
        with_relation: ['blogCategory', 'blogTags', 'media'],
      },

      description: '',
      id: '',
      image: '',
      title: '',
      status: '',
      blogCategoryName: ''
    }
  },

  computed: {
    ...mapGetters({
      blog: 'appBlogs/blog',
      previousRoute: 'previousRoute',
    }),
  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getBlog: 'appBlogs/getBlog',
      putImage: 'appBlogs/putImage',
      commitNotFoundRouteStatus: 'commitNotFoundRouteStatus',
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
    async getSingleBlog() {
      const paramObj = {
        id: this.$route.params.id,
        params: this.getBlogParams,
      };
      await this.getBlog(paramObj).then((response) => {
        if (response.status === 404) {
          this.commitNotFoundRouteStatus(true);
          return;
        }
        if (response && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    openImageUpdateModal() {
      document.querySelector('[data-target="#updateAvatarModal"]').click();
    },

    async uploadImage(imageBase64) {
      this.image = imageBase64;
      const payLoad = {
        id: this.blog.id,
        data: {
          image: imageBase64
        }
      }
      const response = await this.putImage(payLoad);
      if (response.status === 201 || response.status === 200) {
        this.loader(false);
        document.querySelector('[data-target="#updateAvatarModal"]').click();
        await this.getSingleBlog(this.$route.params.id)
      }
      if (response && response.message) {
        this.showToastMessage(response);
      }
    }
  },

  async mounted() {
    await this.loader(true);
    await this.getSingleBlog(this.$route.params.id);
    await this.loader(false);

  },

}
</script>

<style scoped>
.update-blog-image-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #f2f4f4;
  border: 2px solid white;
  top: 80%;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.update-blog-image-avatar:hover {
  background-color: #4173ce;
  color: white;
}

.update-blog-image-avatar > i {
  font-size: 20px;
  z-index: 10;

}
</style>